import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import has from "lodash.has"
import get from "lodash.get"
import BackgroundImage from "gatsby-background-image"
import Team from "../components/Team"
import * as yup from "yup"
import Recaptcha from "react-google-recaptcha"
import $ from "jquery"
import CarouselBg from "../components/CarouselBg"
import { useFormik } from "formik"
import { Helmet } from "react-helmet"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
export default function ContactPage({ data }) {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      phone: "",
      question: "",
      "g-recaptcha-response": "",
    },
    validationSchema: yup.object({
      fullname: yup.string().required("Please enter your name!"),
      email: yup
        .string()
        .email("Please enter a valid email!")
        .required("Please enter your email!"),
      "g-recaptcha-response": yup
        .string()
        .required(
          "Please verify that you are not a robot using the tickbox above. Thanks."
        ),
    }),
    onSubmit: (values) => {
      fetch("/?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...values }),
      })
        .then(() => {
          $("#successMessage").fadeIn().fadeOut(6000)
          resetForm()
        })
        .catch((error) => $("#errorMessage").fadeIn().fadeOut(6000))
    },
  })

  const rkey = process.env.GATSBY_RECAPTCHA_KEY;

  return (
    <Layout>
      <title>
         Contact Us | Softful - Software company Auckland NZ
        </title>
      <div>
        <CarouselBg>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-right in-pic-height">
                <div className="in-text-holder">Contacts Us</div>
                <div
                  className="in-pic-1 in-pic-height"
                  style={{ float: "right" }}
                >
                  <Image
                    style={{ height: "100%" }}
                    fluid={data.in_pic_6.sharp.fluid}
                  />
                </div>
              </div>
            </div>
          </div>
        </CarouselBg>
        <div className="breadcrums">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Link to="/">Home</Link> - Contacts Us
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="content-holder">
          <div className="container">
            <div className="row">
              <div id="sendRequestBlock" className="col-sm-6">
                <h4>SEND REQUEST</h4>
                <form
                  name="contact"
                  onSubmit={handleSubmit}
                  className="form-horizontal form-bcg"
                  id="requestForm"
                  data-netlify-recaptcha="true"
                  data-netlify="true"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="form-group">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className={`form-control ${
                          touched.fullname && errors.fullname
                            ? "form-error"
                            : ""
                        }`}
                        name="fullname"
                        value={values.fullname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Your name *"
                      />
                      {touched.fullname && errors.fullname && (
                        <div className="form-error">{errors.fullname}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className={`form-control ${
                          touched.email && errors.email ? "form-error" : ""
                        }`}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Email address*"
                      />
                      {touched.email && errors.email && (
                        <div className="form-error">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Phone"
                      />
                      {touched.phone && errors.phone && (
                        <div className="form-error">{errors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-12">
                      <textarea
                        className="form-control"
                        rows={4}
                        name="question"
                        value={values.question}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Your request"
                        defaultValue={""}
                      />
                      {touched.question && errors.question && (
                        <div className="form-error">{errors.question}</div>
                      )}
                    </div>
                  </div>
                  {/* <div class="form-group">
    <div class="col-sm-12">
     <select class="form-control">
     <option>How did you find out about us? </option>
     <option>2</option>
     <option>3</option>
     <option>4</option>
     <option>5</option>
     </select>
    </div>
  </div>
*/}
                  <div className="form-group">
                    <div className="col-sm-12">
                      {/* <div
                        className="g-recaptcha"
                        data-sitekey="6LdzQTEUAAAAAIJhAt--pwa-Dch9-KvZQK0P2BvN"
                      /> */}
                      <Recaptcha
                        sitekey={rkey} // TESTING KEY
                        onChange={(response) => {
                          setFieldValue("g-recaptcha-response", response)
                        }}
                      />
                      {touched["g-recaptcha-response"] &&
                        errors["g-recaptcha-response"] && (
                          <div className="form-error">
                            {errors["g-recaptcha-response"]}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-12">
                      <button type="submit" className="btn btn-default btn-req">
                        SEND REQUEST
                      </button>
                    </div>
                  </div>
                  <div
                    id="successMessage"
                    className="alert collapse alert-success  "
                  >
                    <strong>Success!</strong> Thank you for contacting us, we
                    will be in touch soon.
                  </div>
                  <div
                    id="errorMessage"
                    className="alert alert-danger collapse "
                  >
                    <strong>Sorry!</strong> Something went wrong, please try
                    sending the form again!
                  </div>
                </form>
              </div>
              <div
                className="col-sm-6"
                css={css`
                  margin-top: 2rem;
                `}
              >
                <div className="row">
                  <div className="col-sm-6">
                    {" "}
                    <h4 className="">PHYSICAL ADDRESS</h4>
                    <p>
                      17 Albert Street
                      <br />
                      CBD
                      <br />
                      Auckland, 1010
                      <br />
                      New Zealand
                    </p>
                  </div>
                  <div className="col-sm-6">
                    {" "}
                    <h4>POSTAL ADDRESS</h4>
                    <p>
                      101 Liberty Crescent
                      <br />
                      Beachlands
                      <br />
                      Auckland, 2018
                      <br />
                      New Zealand
                    </p>
                  </div>
                </div>
                <div className="divider-3" />
                <div className="row">
                  <div className="col-sm-6">
                    <h4>PHONE</h4>
                    <p>09 368 7607</p>
                  </div>
                  <div className="col-sm-6">
                    <h4>MOBILE</h4>
                    <p>022 187 4188</p>
                  </div>
                </div>
                <div className="divider-3" />
                <div className="row">
                  <div className="col-sm-12">
                    <h4>Social media</h4>
                    <a
                      href="https://www.facebook.com/softful/"
                      className="soc-link-2 sc-bcg-2"
                    >
                      Follow us on Facebook
                    </a>
                    {/* <a href="#" class="soc-link-2 sc-bcg-1">Read our latest Tweets</a>
  <a href="#" class="soc-link-2 sc-bcg-3">Subscribe to our channel</a>  */}
                  </div>
                </div>
                <div className="divider-3" />
              </div>
            </div>
          </div>
        </div>
        <div className="map-holder">
          <iframe
            src="https://www.google.com/maps?q=Level+4,+17+Albert+Street+Auckland+CBD,+1010&output=embed"
            width="100%"
            height={350}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen
            title="Map"
          />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    in_pic_6: file(publicURL: { regex: "/in_pic_6.png/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 641) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
